// const awsmobile = {
//   aws_project_region: 'us-east-1',
//   aws_cognito_identity_pool_id: 'us-east-1:a903c3a6-56f4-4ea2-8cd1-4e0954568908',
//   aws_cognito_region: 'us-east-1',
//   aws_user_pools_id: 'us-east-1_rKz883f2j',
//   aws_user_pools_web_client_id: '13ojrikjgp5vj57vbfb8n9d4mj',
//   oauth: {
//     domain: 'appsdev-skfilluminate.auth.us-east-1.amazoncognito.com',
//     scope: [
//       'phone',
//       'email',
//       'openid',
//       'profile',
//       'aws.cognito.signin.user.admin'
//     ],
//     redirectSignIn: 'https://linkdev2.skfilluminate.net',
//     redirectSignOut: 'https://linkdev2.skfilluminate.net',
//     responseType: 'code'
//   },
//   federationTarget: 'COGNITO_USER_POOLS'
// };


// export default awsmobile;

// const awsmobile = {
//   aws_project_region: 'us-east-1',
//   aws_cognito_identity_pool_id: 'us-east-1:a903c3a6-56f4-4ea2-8cd1-4e0954568908',
//   aws_cognito_region: 'us-east-1',
//   aws_user_pools_id: 'us-east-1_S3FrUD37O',
//   aws_user_pools_web_client_id: '5gda4tr0esitjadn5q85kqqcv1',
//   oauth: {
//     domain: 'appsuat-skfilluminate.auth.us-east-1.amazoncognito.com',
//     scope: [
//       'phone',
//       'email',
//       'openid',
//       'profile',
//       'aws.cognito.signin.user.admin'
//     ],
//     redirectSignIn: 'https://linkuat.skfilluminate.net',
//     redirectSignOut: 'https://linkuat.skfilluminate.net',
//     responseType: 'code'
//   },
//   federationTarget: 'COGNITO_USER_POOLS'
// };


// export default awsmobile;

const awsmobile = {
  aws_project_region: 'us-east-1',
  aws_cognito_identity_pool_id: 'us-east-1:a903c3a6-56f4-4ea2-8cd1-4e0954568908',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_I8w0IBvBO',
  aws_user_pools_web_client_id: '7o18d4eh9f29i6d0753tdvuns4',
  oauth: {
    domain: 'apps-skfilluminate.auth.us-east-1.amazoncognito.com',
    scope: [
      'phone',
      'email',
      'openid',
      'profile',
      'aws.cognito.signin.user.admin'
    ],
    redirectSignIn: 'https://link.skfilluminate.net',
    redirectSignOut: 'https://link.skfilluminate.net',
    responseType: 'code'
  },
  federationTarget: 'COGNITO_USER_POOLS'
};


export default awsmobile;