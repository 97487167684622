import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AdminComponent} from './layouts/admin/admin.component';
import {SKFAuthGuard, SKFIdentityGuard} from '@skf/auth';
import { LoginGuard } from './login.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    canActivate: [LoginGuard],
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'notifications',
    component: AdminComponent,
    canActivate: [SKFAuthGuard, SKFIdentityGuard],
    loadChildren: () => import('./components/notifications/notifications.module').then(m => m.NotificationsModule)
  },
  {
    path: 'questions',
    component: AdminComponent,
    canActivate: [SKFAuthGuard, SKFIdentityGuard],
    loadChildren: () => import('./components/communications/communications.module').then(m => m.CommunicationsModule)
  },
  {
    path: 'links',
    component: AdminComponent,
    canActivate: [SKFAuthGuard, SKFIdentityGuard],
    loadChildren: () => import('./components/app-versioning/app-versioning.module').then(m => m.AppVersioningModule)
  },
  {
    path: 'surveys',
    component: AdminComponent,
    canActivate: [SKFAuthGuard, SKFIdentityGuard],
    loadChildren: () => import('./components/surveys/survey.module').then(m => m.SurveyModule)
  },
  // {
  //   path: 'faq',
  //   component: AdminComponent,
  //   canActivate: [SKFAuthGuard, SKFIdentityGuard],
  //   loadChildren: () => import('./components/faqs/faq.module').then(m => m.FaqModule)
  // },
  // {
  //   path: 'news',
  //   component: AdminComponent,
  //   canActivate: [SKFAuthGuard, SKFIdentityGuard],
  //   loadChildren: () => import('./components/news-event/news-event.module').then(m => m.NewsEventModule)
  // },
  {
    path: 'newshub',
    component: AdminComponent,
    canActivate: [SKFAuthGuard, SKFIdentityGuard],
    loadChildren: () => import('./components/news-story/news-story.module').then(m => m.NewsStoryModule)
  },
  {
    path: 'categories',
    component: AdminComponent,
    canActivate: [SKFAuthGuard, SKFIdentityGuard],
    loadChildren: () => import('./components/categories/category.module').then(m => m.CategoryModule)
  },
  {
    path: 'timeoff',
    component: AdminComponent,
    canActivate: [SKFAuthGuard, SKFIdentityGuard],
    loadChildren: () => import('./components/timeoff/timeoff.module')
      .then(m => m.TimeoffModule)
  },  
  {
    path: 'organnouncement',
    component: AdminComponent,
    canActivate: [SKFAuthGuard, SKFIdentityGuard],
    loadChildren: () => import('./components/org-announcement/org-announcement.module')
      .then(m => m.OrgAnnouncementModule)
  },  
  {
    path: 'colleague',
    component: AdminComponent,
    canActivate: [SKFAuthGuard, SKFIdentityGuard],
    loadChildren: () => import('./components/colleague-corner/colleague-corner.module')
      .then(m => m.ColleagueCornerModule)
  },  
  {
    path: 'analytics',
    component: AdminComponent,
    canActivate: [SKFAuthGuard, SKFIdentityGuard],
    loadChildren: () => import('./components/analytics/analytics.module')
      .then(m => m.AnalyticsModule)
  },  
  {
    path: '**',
    redirectTo: 'notifications'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
