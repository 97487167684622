import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-skf-modal',
  templateUrl: './skf-modal.component.html',
  styleUrls: ['./skf-modal.component.scss']
})
export class SkfModalComponent implements OnInit {
  @Input() type = 'content'; // or dialog
  @Input() title?: string;
  @Input() dialog?: string;
  @Input() icon?: string;
  @Input() cancelText = 'Cancel';
  @Input() confirmText = 'Yes';
  @Input() isNotValid = false;
  @Output() cancelEvent = new EventEmitter();
  @Output() confirmEvent = new EventEmitter();
  @Output() closeEvent = new EventEmitter();

  constructor() {
    this.title = 'sample title';
    this.dialog = 'sample dialog';
  }

  ngOnInit() {
  }

  private onClose() {
    this.closeEvent.emit();
  }

  private onCancel() {
    this.cancelEvent.emit();
  }

  private onConfirm() {
    this.confirmEvent.emit();
  }
}
