import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatCheckboxChange} from '@angular/material';

@Component({
  selector: 'app-skf-checkbox',
  templateUrl: './skf-checkbox.component.html',
  styleUrls: ['./skf-checkbox.component.scss']
})
export class SkfCheckboxComponent implements OnInit {
  @Input() checked: boolean;
  @Input() indeterminate: boolean;
  @Input() name = '';
  @Input() label = '';
  @Input() labelPosition: 'after' | 'before';
  @Output() changeEvent = new EventEmitter<MatCheckboxChange>();

  constructor() {
  }

  ngOnInit() {
  }

  public doChange(event: MatCheckboxChange) {
    this.changeEvent.emit(event);
  }

}
