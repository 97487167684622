import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FlexLayoutModule} from '@angular/flex-layout';
import {ReactiveFormsModule} from '@angular/forms';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatCheckboxModule,
  MatChipsModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatOptionModule,
  MatPaginatorModule,
  MatRippleModule,
  MatSortModule,
  MatTableModule
} from '@angular/material';
import {DragDropModule} from '@angular/cdk/drag-drop';

import {SkfCardComponent} from './components/skf-card/skf-card.component';
import {SkfDataTableComponent} from './components/skf-data-table/skf-data-table.component';
import {SkfTextFieldComponent} from './components/skf-text-field/skf-text-field.component';
import {SkfTextAreaComponent} from './components/skf-text-area/skf-text-area.component';
import {SkfCheckboxComponent} from './components/skf-checkbox/skf-checkbox.component';
import {SkfButtonComponent} from './components/skf-button/skf-button.component';
import {SkfSearchModalViewComponent} from './components/skf-search-modal-view/skf-search-modal-view.component';
import {SkfAutocompleteComponent} from './components/skf-autocomplete/skf-autocomplete.component';
import {SkfLabelComponent} from './components/skf-label/skf-label.component';
import {SkfToggleComponent} from './components/skf-toggle/skf-toggle.component';
import {SkfModalComponent} from './components/skf-modal-ui/skf-modal.component';
import {SkfViewCardComponent} from './components/skf-view-card/skf-view-card.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    SkfDataTableComponent,
    SkfCardComponent,
    SkfTextFieldComponent,
    SkfCheckboxComponent,
    SkfTextAreaComponent,
    SkfButtonComponent,
    SkfSearchModalViewComponent,
    SkfAutocompleteComponent,
    SkfLabelComponent,
    SkfToggleComponent,
    SkfModalComponent,
    SkfViewCardComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    // material modules
    MatTableModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatAutocompleteModule,
    MatOptionModule,
    MatFormFieldModule,
    MatInputModule,
    MatSortModule,
    MatPaginatorModule,
    MatRippleModule,
    MatChipsModule,
    MatButtonModule,
    DragDropModule,
    TranslateModule.forChild({extend: true})
  ],
  exports: [
    SkfDataTableComponent,
    SkfCardComponent,
    SkfTextFieldComponent,
    SkfTextAreaComponent,
    SkfCheckboxComponent,
    SkfButtonComponent,
    SkfAutocompleteComponent,
    SkfLabelComponent,
    SkfToggleComponent,
    SkfModalComponent,
    SkfSearchModalViewComponent,
    SkfViewCardComponent
  ]
})
export class SkfUiModule {
}
